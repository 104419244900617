import React, { createContext, useContext, useState, useEffect } from "react";

// Create context
const MobilizerContext = createContext();

// Create provider component
export const MobilizerProvider = ({ children }) => {
  // State management
  const [mobilizers, setMobilizers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMobilizer, setSelectedMobilizer] = useState(null);

  // Fetch all mobilizers
  const fetchMobilizers = async () => {
    setLoading(true);
    try {
      // Replace with actual API call
      const response = await fetch('/api/mobilizers');
      const data = await response.json();
      setMobilizers(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch mobilizers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Create a new mobilizer
  const createMobilizer = async (mobilizerData) => {
    setLoading(true);
    try {
      // Replace with actual API call
      const response = await fetch('http://localhost:19093/api/v1/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mobilizerData),
      });
      
      const newMobilizer = await response.json();
      setMobilizers([...mobilizers, newMobilizer]);
      setError(null);
      return newMobilizer;
    } catch (err) {
      setError('Failed to create mobilizer: ' + err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Update an existing mobilizer
  const updateMobilizer = async (id, mobilizerData) => {
    setLoading(true);
    try {
      // Replace with actual API call
      const response = await fetch(`/api/mobilizers/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mobilizerData),
      });
      
      const updatedMobilizer = await response.json();
      setMobilizers(
        mobilizers.map(mobilizer => 
          mobilizer.id === id ? updatedMobilizer : mobilizer
        )
      );
      
      if (selectedMobilizer && selectedMobilizer.id === id) {
        setSelectedMobilizer(updatedMobilizer);
      }
      
      setError(null);
      return updatedMobilizer;
    } catch (err) {
      setError('Failed to update mobilizer: ' + err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Delete a mobilizer
  const deleteMobilizer = async (id) => {
    setLoading(true);
    try {
      // Replace with actual API call
      await fetch(`/api/mobilizers/${id}`, {
        method: 'DELETE',
      });
      
      setMobilizers(mobilizers.filter(mobilizer => mobilizer.id !== id));
      
      if (selectedMobilizer && selectedMobilizer.id === id) {
        setSelectedMobilizer(null);
      }
      
      setError(null);
    } catch (err) {
      setError('Failed to delete mobilizer: ' + err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Get a single mobilizer by ID
  const getMobilizerById = async (id) => {
    setLoading(true);
    try {
      // Check if we already have it in our state
      const existingMobilizer = mobilizers.find(m => m.id === id);
      if (existingMobilizer) {
        setSelectedMobilizer(existingMobilizer);
        setLoading(false);
        return existingMobilizer;
      }
      
      // Otherwise fetch from API
      const response = await fetch(`/api/mobilizers/${id}`);
      const data = await response.json();
      setSelectedMobilizer(data);
      setError(null);
      return data;
    } catch (err) {
      setError('Failed to fetch mobilizer: ' + err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Clear the selected mobilizer
  const clearSelectedMobilizer = () => {
    setSelectedMobilizer(null);
  };

  // Clear any errors
  const clearError = () => {
    setError(null);
  };

  // Load mobilizers when component mounts
  useEffect(() => {
    fetchMobilizers();
  }, []);

  // Context value
  const value = {
    mobilizers,
    loading,
    error,
    selectedMobilizer,
    fetchMobilizers,
    createMobilizer,
    updateMobilizer,
    deleteMobilizer,
    getMobilizerById,
    setSelectedMobilizer,
    clearSelectedMobilizer,
    clearError
  };

  return (
    <MobilizerContext.Provider value={value}>
      {children}
    </MobilizerContext.Provider>
  );
};

// Custom hook for using mobilizer context
export const useMobilizer = () => {
  const context = useContext(MobilizerContext);
  if (context === undefined) {
    throw new Error('useMobilizer must be used within a MobilizerProvider');
  }
  return context;
};

export default MobilizerContext;