import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';
import { format } from 'date-fns';
import moment from 'moment';


const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const getSession = () => sessionStorage.getItem('accessToken');

const jwtAuthHeader = {
  headers: {
    Authorization: 'Bearer ' + getSession(),
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_BUSINESS_NAME': {
      var b = {
        ...state,
        loaded: true,
        businessName: action.payload,
      };

      return b;
    }
    case 'UPDATE_MOVE_READER': {
      var b = {
        ...state,
        isMoveReadingLoading: 2,
        moveReaderData: action.payload,
      };

      return b;
    }

    case 'CLEAR_MOVE_READER': {
      var b = {
        ...state,
        isMoveReadingLoading: 1,
        moveReaderData: {},
      };

      return b;

    }
    case 'UPDATE_PUSH_NOTIFICATION': {
      var b = {
        ...state,
        isPushLoading: 2,
        pushData: action.payload,
      };

      return b;
    }

    case 'UPDATE_INTERNAL_STATEMENT': {
      var b = {
        ...state,
        isGenerateAccountStatementLoading: 2,
        internalGenerate: action.payload,
      };

      return b;

    }
    case 'CLEAR_UPDATE_BUSINESS_NAME': {
      var b = {
        ...state,
        loaded: false,
        businessName: {},
      };

      return b;
    }

    case 'CLEAR_INTERNAL_STATEMENT': {
      var b = {
        ...state,
        isGenerateAccountStatementLoading: 1,
        internalGenerate: {},
      };

      return b;
    }

    case 'CLEAR_UPDATE_PUSH_NOTIFICATION': {
      var b = {
        ...state,
        isPushLoading: 1,
        pushData: {},
      };

      return b;
    }

    case 'LOAD_BVN_DATA': {
      var b = {
        ...state,
        isBvnLoading: 2,
        bvn: action.payload,
      };

      return b;
    }

    case 'CLEAR_BVN_DATA': {
      var b = {
        ...state,
        isBvnLoading: 1,
        bvn: {},
      };

      return b;
    }
    case 'LOAD_STATUS_DATA': {
      var b = {
        ...state,
        isTransLoading: 2,
        transStatus: action.payload,
      };

      return b;
    }

    case 'CLEAR_STATUS_DATA': {
      var b = {
        ...state,
        isTransLoading: 1,
        transStatus: {},
      };

      return b;
    }

    case 'LOAD_ASSIGN_ACCOUNT_DATA': {
      var b = {
        ...state,
        isAssignAccountNumber: 2,
        assignAccountNumberData: action.payload,
      };

      return b;
    }

    case 'CLEAR_ASSIGN_ACCOUNT_DATA': {
      var b = {
        ...state,
        isAssignAccountNumber: 1,
        transStatus: {},
      };

      return b;
    }

    default: {
      return { ...state };
    }
  }
};

const AdvancedContext = createContext({
  loaded: !false,
  isBvnLoading: 0,
  isTransLoading: 0,
  isPushLoading: 0,
  isGenerateAccountStatementLoading: 0,
  isAssignAccountNumber: 0,
  generateAccountStatement: () => { },
  assignAccountNumberData: {},
  bvn: {},
  pushData: {},
  assignAccountNumber: () => { },
  transStatus: {},
  businessName: {},
  getTransactionStatus: () => { },
  updateBusinessName: () => { },
  moveReader: () => { },
  sendPushNotification: () => { },
  getBVNData: () => { },
});

export const AdvancedProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);

  const updateBusinessName = async (phoneNumber, businessName) => {
    try {
      dispatch({
        type: 'CLEAR_UPDATE_BUSINESS_NAME',
        payload: {},
      });

      // // console.log('------------ kuje');
      const res = await axios.put(
        'https://api.crediometer.com/api/v1/admin/credio/update/userData',
        {
          phoneNumber,
          businessName,
        },
        jwtAuthHeader,

      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'UPDATE_BUSINESS_NAME',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };
  const moveReader = async (phoneNumber, businessName) => {
    try {
      dispatch({
        type: 'CLEAR_MOVE_READER',
        payload: {},
      });

      // // console.log('------------ kuje');
      const res = await axios.put(
        'https://api.crediometer.com/api/v1/admin/credio/update/userData',
        {
          phoneNumber,
          businessName,
        },
        jwtAuthHeader,

      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'UPDATE_MOVE_READER',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const generateAccountStatement = async (phoneNumber, email, startDate1, endDate2, esigned) => {
    try {
      dispatch({
        type: 'CLEAR_INTERNAL_STATEMENT',
        payload: {},
      });
      const startDate = moment(new Date(startDate1)).format("YYYY-MM-DD[T00:00:00.000Z]");
      const endDate = moment(new Date(endDate2)).format("YYYY-MM-DD[T00:00:00.000Z]");

      console.log('------------ kuje', {
        phoneNumber,
        email,
        startDate,
        endDate
      });
      const res = await axios.post(
        esigned ? "https://api.crediometer.com/api/v1/admin/credio/generate/transaction/history/esigned" : 'https://api.crediometer.com/api/v1/admin/credio/generate/transaction/history',
        {
          phoneNumber,
          email,
          startDate,
          endDate
        },
        jwtAuthHeader,

      );
      dispatch({
        type: 'UPDATE_INTERNAL_STATEMENT',
        payload: res.data,
      });
    } catch (e) {

    }
  };
  const sendPushNotification = async (title,
    message, date) => {
    const sendTime = moment(new Date(date)).format("YYYY-MM-DDTHH:mm:ss.[000Z]");

    try {
      dispatch({
        type: 'CLEAR_UPDATE_PUSH_NOTIFICATION',
        payload: {},
      });

      const res = await axios.post(
        'https://api.crediometer.com/api/v1/admin/send/notifications/all', //https://api.crediometer.com/
        {
          title,
          message,
          sendTime
        },
        jwtAuthHeader,

      );
      console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'UPDATE_PUSH_NOTIFICATION',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const getBVNData = async (bvn) => {
    try {
      // // console.log('------------ kuje new get new data ', date);

      dispatch({
        type: 'CLEAR_BVN_DATA',
        payload: {},
      });

      const res = await axios.post(
        'https://api.crediometer.com/api/v1/admin/tools/bvn/get',
        {
          bvn: bvn
        },
        jwtAuthHeader,
      );



      console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_BVN_DATA',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  const assignAccountNumber = async (phoneNumber) => {
    try {
      // // console.log('------------ kuje new get new data ', date);

      dispatch({
        type: 'CLEAR_ASSIGN_ACCOUNT_DATA',
        payload: {},
      });

      const res = await axios.put(
        'https://api.crediometer.com/api/v1/admin/credio/assign/accountNumber',
        {
          phoneNumber: phoneNumber,
        },
        jwtAuthHeader,
      );



      console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_ASSIGN_ACCOUNT_DATA',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const getTransactionStatus = async (sessionId, transType) => {
    try {
      // // console.log('------------ kuje new get new data ', date);

      dispatch({
        type: 'CLEAR_STATUS_DATA',
        payload: {},
      });

      const res = await axios.post(
        'https://api.crediometer.com/api/v1/admin/tools/transaction/status',
        {
          sessionId: sessionId,
          transType: transType
        },
        jwtAuthHeader,
      );



      console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_STATUS_DATA',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  useEffect(() => {
    // // console.log('cautions fee');
    // if(){

    // }else{
    //     getSavings();
    // }
  }, []);

  return (
    <AdvancedContext.Provider
      value={{
        savings: state.savings ?? {},
        loaded: state.loaded ?? false,
        updateBusinessName,
        pushData: state.pushData ?? {},
        isPushLoading: state.isPushLoading ?? 0,
        sendPushNotification,
        businessName: state.businessName ?? {},
        getBVNData,
        isMoveReadingLoading: state.isMoveReadingLoading ?? 0,
        moveReaderData: state.moveReaderData ?? {},
        moveReader,
        generateAccountStatement,
        isGenerateAccountStatementLoading: state.isGenerateAccountStatementLoading ?? 0,
        internalGenerate: state.internalGenerate ?? {},
        assignAccountNumber,
        isAssignAccountNumber: state.isAssignAccountNumber ?? 0,
        assignAccountNumberData: state.assignAccountNumberData ?? {},
        transStatus: state.transStatus ?? {},
        getTransactionStatus,
        isBvnLoading: state.isBvnLoading ?? 0,
        isTransLoading: state.isTransLoading ?? 0,
        bvn: state.bvn ?? {},
      }}
    >
      {children}
    </AdvancedContext.Provider >
  );
};

export default AdvancedContext;
