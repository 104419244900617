export const navigations = (userType) => [
  { name: 'Credio', path: '/dashboard', icon: 'dashboard' },
  { label: 'Tools', type: 'label' },
  ...(userType === 2
    ? [
      { name: 'Savings', path: '/analytics/savings', icon: 'account_balance' },
    ]
    : userType === 3 ? [
      { name: 'Advace Tools', icon: 'people_outline', path: '/tools/advance' },
      { name: 'Workplace', path: '/analytics/workplace', icon: 'markunread_mailbox' },
      { name: 'Inventory', path: '/analytics/inventory', icon: 'pan_tool' },
      { name: 'Savings', path: '/analytics/savings', icon: 'account_balance' },
      { name: 'Sells', icon: 'attach_money', path: '/analytics/sells' },
      { name: 'School', icon: 'school', path: '/analytics/school' },
      { name: 'Product Mobilizer', iconText: 'people_outline', path: '/analytics/mobilizers' },
      { name: 'Cooperative', icon: 'people_outline', path: '/tools/advance' },
      { name: 'Dinepoint', icon: 'restaurant', path: '/tools/dinepoint' },
      { name: 'Notepad', icon: 'note', path: '/analytics/notepad' },
      { name: 'Appointment', icon: 'date_range', path: '/analytics/appoitment' },
      { name: 'Hoop', icon: 'group', path: '/analytics/hoop' },
      { name: 'Payroll', icon: 'payment', path: '/analytics/payroll' },
      // { name: '', iconText: '404', path: '/auth/404' },
    ] : []),
  {
    name: 'Profile',
    icon: 'security',
    children: [
      { name: 'Edit Profile', iconText: 'FP', path: '/auth/404' },
      { name: 'Change password', iconText: 'SI', path: '/auth/404' },
    ],
  },

  { name: 'Logout', path: '/', icon: 'power_settings_new' },

];
