// import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { ConfigProvider } from 'antd';
import { MobilizerProvider } from './contexts/MobilizerContext';

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                controlHeight: 40, // Set your desired height
                // You can also adjust related sizes
                controlHeightLG: 48,
                controlHeightSM: 32,
              },
            },
          }}
        >


          <MatxTheme>
            <AuthProvider>
              <MobilizerProvider>
                {content}
              </MobilizerProvider>
            </AuthProvider>
          </MatxTheme>
        </ConfigProvider>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
